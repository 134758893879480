import React from "react"
import Helmet from "react-helmet"

const matadatasmsf = () => {
  return (
    <div>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>SMSF Loans | Oxygen - Home Loans Made Simple</title>
        <meta name="title" content="SMSF Loans" />
        <meta
          name="description"
          content="Smsf, smsf loans, best smsf loans, smsf loan rates, smsf loan calculator, smsf loans for property, smsf loans for commercial property, smsf loan providers, smsf loans cba,
smsf loan requirements, smsf lending, smsf loan interest rates, self managed super fund loans,
self managed super fund loan rates, self managed super fund property, self managed super fund commercial property,
"
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.facebook.com/OxygenHomeLoans"
        />
        <meta property="og:title" content="smsf loans" />
        <meta
          property="og:description"
          content="Smsf, smsf loans, best smsf loans, smsf loan rates, smsf loan calculator, smsf loans for property, smsf loans for commercial property, smsf loan providers, smsf loans cba,
smsf loan requirements, smsf lending, smsf loan interest rates, self managed super fund loans,
self managed super fund loan rates, self managed super fund property, self managed super fund commercial property,
"
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/photo/?fbid=496616752468059&set=a.496616729134728"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:title" content="smsf loans" />
        <meta
          property="twitter:description"
          content="Smsf, smsf loans, best smsf loans, smsf loan rates, smsf loan calculator, smsf loans for property, smsf loans for commercial property, smsf loan providers, smsf loans cba,
smsf loan requirements, smsf lending, smsf loan interest rates, self managed super fund loans,
self managed super fund loan rates, self managed super fund property, self managed super fund commercial property,
"
        />
        {/* <meta property="twitter:image" content="https://www.facebook.com/photo/?fbid=496616752468059&set=a.496616729134728" /> */}
      </Helmet>
    </div>
  )
}

export default matadatasmsf
